<template>
  <div class="nd-select w-full">
    <n-config-provider :theme-overrides="themeOverrides">
      <n-select v-model:value="value" :options="props.options" :show-checkmark="false" v-bind="$attrs">
        <template #arrow>
          <svg-icon name="arrow-down" class="w-5 h-5" />
        </template>
      </n-select>
    </n-config-provider>
  </div>
</template>

<script setup lang="ts">
import type { GlobalThemeOverrides } from 'naive-ui'
import { NConfigProvider, NSelect } from 'naive-ui'
import _merge from 'lodash/merge'

import { colors } from '@/theme.config'

interface Props {
  options: {
    label: string
    value: string | number
  }[]
  withBorder?: boolean
  nUiThemeOverrides?: GlobalThemeOverrides
}

const value = defineModel<string>()

const props = withDefaults(defineProps<Props>(), {
  withBorder: true,
  options: () => [],
})

const themeOverrides = computed<GlobalThemeOverrides>(() => {
  const noBorderThemes = {
    border: 'none',
    borderHover: 'none',
    borderActive: 'none',
    borderFocus: 'none',
    boxShadowActive: 'none',
    boxShadowFocus: 'none',
  }

  const baseThemes = {
    common: {
      heightMedium: '40px',
    },
    Select: {
      peers: {
        InternalSelection: {
          paddingSingle: '8px 48px 8px 16px',
          arrowSize: '20px',
          arrowColor: colors.black[60],
          ...(!props.withBorder && { ...noBorderThemes }),
        },
        InternalSelectMenu: {
          paddingMedium: '8px 16px',
          optionTextColorPressed: colors.black[50],
          optionTextColorActive: colors.yellow[50],
          optionColorActive: colors.white,
          optionPaddingMedium: '12px 20px',
        },
      },
    },
    Icon: {
      sizeMedium: '24px',
    },
  }

  return _merge(baseThemes, props.nUiThemeOverrides)
})
</script>

<style lang="scss" scoped>
.nd-select {
  :deep(.n-select) {
    .n-base-loading.n-base-suffix {
      @apply right-4;
    }
  }
}
</style>
<style lang="scss">
.n-select-menu.n-base-select-menu .n-base-select-option::before {
  @apply left-0 right-0;
}
</style>
